<template>
  <div class="page_container">
    <div class="play-root">
      <video src="../assets/video/首页.mp4" muted autoplay loop id="dplayer"></video>
      <div class="title">
        <h1>善建未来，匠心致远</h1>
        <p class="alignLeft">黑洞引擎是秉匠科技联合长安大学共同研发完成，拥有完全国产自主知识产权，是具有国际领先水平的BIM+GIS轻量化渲染引擎，可承载海量数据、实现秒级加载、呈现逼真效果。</p>
        <!-- <span @click="to('contactus')">立即开始</span> -->
      </div>
    </div>
    <!-- 黑洞介绍 -->
    <div class="nebula">
      <div class="container">
        <div class="title">
          <div class="logo" @click="tohd">
            <!-- <img src="../assets/image/nebula/logo.png" alt="" class="left"> -->
            <p style="text-align: center;">工程三维图形引擎</p>
          </div>
          <p class="info">
            黑洞引擎是秉匠科技自主研发的，具有自主知识产权的BIM+GIS三维图形引擎。服务于基础工程行业各个阶段的三维可视化管理，致力于泛工程行业相关信息的价值体现与增值应用。
          </p>
        </div>
        <div class="pic">
          <img src="../assets/image/nebula_index.png" alt="">
          <!-- <img src="../assets/image/nebula/pic2.png" alt="" class="left">
          <img src="../assets/image/nebula/pic3.png" alt="" class="left">
          <img src="../assets/image/nebula/pic4.png" alt="" class="left">
          <img src="../assets/image/nebula/pic5.png" alt="" class="left"> -->
          <div class="play center" @click="showvedio"><i class="el-icon-video-play"></i></div>
        </div>
        <!-- <div class="vediobox">
          <video src="../assets/video/光线.mp4" muted autoplay loop></video>
        </div> -->
        <div class="bottom">易用<i></i> 开放<i></i> 高效<i></i> 共享</div>
      </div>
    </div>
    <!-- 轮播区域 -->
    <div class="swiper">
      <div class="container">
        <div class="top">
          <h1>秉匠科技助力工程建设</h1>
          <p>秉匠科技将助力企业完成数字化转型</p>
          <p>促进建筑业数字化、可视化、智能化升级，最终帮助施工建设企业提升行业竞争力，释放无限可能</p>
        </div>
      </div>
       <el-divider></el-divider>
       <div class="container">
          <el-carousel :interval="3000" type="card" height="200px">
            <el-carousel-item>
              <img src="../assets/image/swiper/pic1.png" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="../assets/image/swiper/pic2.png" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="../assets/image/swiper/pic3.png" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="../assets/image/swiper/pic4.png" alt="">
            </el-carousel-item>
          </el-carousel>
          <div class="more" @click="to('case')">查看更多案例</div>
       </div>
    </div>
    <!--新闻案例  -->
    <div class="news">
      <div class="container">
        <div class="top">公司新闻</div>
        <div class="newslist">
          <ul>
            <!-- <li>
              <img src="../assets/image/news/new_chaotu01.jpg" alt="" @click="to('newsDetails_new_chaotu')">
              <div class="info">
                <div class="time"><span>NEWS</span>2020-11-26</div>
                <p @click="to('newsDetails_new_dongjiao')">秉匠科技赴超图软件拜访交流</p>
                <p>11月25日，受北京超图软件股份有限公司研究院邀请，秉匠科技夏海兵总经理带队前往超图南京分公司进行技术交流，超图研究院副院长、三维研发中心总经理冯振华主持会议。</p>
              </div>
            </li>
            <li>
              <img src="../assets/image/news/new_changan.jpg" alt="" @click="to('newsDetails_new_changan')">
              <div class="info">
                <div class="time"><span>NEWS</span>2020-11-25</div>
                <p @click="to('newsDetails_new_hj')">国产引擎铸“大国重器”——长安大学申报的国家重点研发计划项目获批复</p>
                <p>近日，科技部公布了2020年度国家重点研发计划“重大自然灾害监测预警与防范”重点专项立项情况。</p>
              </div>
            </li>
            <li>
              <img src="../assets/image/news/news_cim01.jpg" alt="" @click="to('newsDetails_new_CIM')">
              <div class="info">
                <div class="time"><span>NEWS</span>2020-11-09</div>
                <p @click="to('newsDetails_new_CIM')">“黑洞”图形引擎助力中电建华东院打造智慧城市CIM平台</p>
                <p>近日，中国电建集团华东勘测设计研究院有限公司与上海秉匠信息科技有限公司签订“黑洞”BIM+GIS 三维图形引擎采购合同。</p>
              </div>
            </li>
            <li>
              <img src="../assets/image/news/new_pudong01.jpg" alt="" @click="to('newsDetails_new_BIM')">
              <div class="info">
                <div class="time"><span>NEWS</span>2020-10-28</div>
                <p @click="to('newsDetails_new_BIM')">秉匠科技又双叒叕获奖了！</p>
                <p>10月27日，在2020年第三届浦东新区BIM技术应用创新劳动和技能竞赛暨长三角区域邀请赛表彰大会上，秉匠科技的“黑洞”图形引擎荣获最佳图形引擎奖一等奖。</p>
              </div>
            </li> -->
            <li v-for="item in newslist" :key="item.id">
              <img :src="item.newsTitlePicUrl" alt="" @click="tonewsdetails(item.id)">
              <div class="info">
                <div class="time"><span>NEWS</span>{{item.newsTime}}</div>
                <p @click="tonewsdetails(item.id)">{{item.newsTitle}}</p>
                <p>{{item.newsParticularsTitle}}</p>
              </div>
            </li>
          </ul>
          <div class="more" @click="gonews">阅读更多<i class="el-icon-circle-plus"></i></div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="partner">
      <div class="container">
          <div class="top">
            <p>合作伙伴</p>
            <p>我们与国内多个高校及的知名科研机构一起，打造高品质的高科技研发、生产及应用平台，助力数字化转型构建数字孪生生态</p>
          </div>
          <div class="bottom">
            <div v-for="(item, ind) in initCooperationList" class="partner_itemStyle" :key="ind">
              <img :src="item" alt />
            </div>

            <el-divider content-position="center">
              <el-button @click="handleMore" type="text">{{isMore?'收起':'加载更多'}}
                <i v-if="!isMore" class="el-icon-arrow-down"></i>
                <i v-else class="el-icon-arrow-up"></i>
              </el-button>
            </el-divider>
          </div>
      </div>
    </div>
    <!-- 视频展示 -->
    <el-dialog title="" :visible.sync="dialogTableVisible" fullscreen lock-scroll :modal-append-to-body="false">
      <div class="videobox">
        <video src="../assets/video/黑洞视频.mp4" muted autoplay loop controls></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import collegeLanzhouJiaotongUniversity from '../assets/image/cooperation/cooperationNew/college/lanzhouJiaotongUniversity.png'
import collegeChanganUniversity from '../assets/image/cooperation/cooperationNew/college/changanUniversity.png'
import collegeShenzhenjishuUniversity from '../assets/image/cooperation/cooperationNew/college/shenzhenjishuUniversity.png'
import collegeQinghuaUniversity from '../assets/image/cooperation/cooperationNew/college/qinghuaUniversity.png'
import collegeTongjiUniversity from '../assets/image/cooperation/cooperationNew/college/tongjiUniversity.png'
import constructionZhejianggaoxin from '../assets/image/cooperation/cooperationNew/construction/zhejianggaoxin.png'
import constructionShanghaiShizhengyuan from '../assets/image/cooperation/cooperationNew/construction/shanghaiShizhengyuan.png'
import constructionShanghaiZhujianwei from '../assets/image/cooperation/cooperationNew/construction/shanghaiZhujianwei.png'
import constructionLuqiaojituan from '../assets/image/cooperation/cooperationNew/construction/luqiaojituan.png'
import constructionZhongtie17ju from '../assets/image/cooperation/cooperationNew/construction/zhongtie18ju.png'
import constructionShanghaipuxinGuihuashejiyuan from '../assets/image/cooperation/cooperationNew/construction/shanghaipuxinGuihuashejiyuan.png'
import constructionNantongsijian from '../assets/image/cooperation/cooperationNew/construction/nantongsijian.png'
import constructionShanghaiChengjian from '../assets/image/cooperation/cooperationNew/construction/shanghaiChengjian.png'
import constructionZhongguoXiongan from '../assets/image/cooperation/cooperationNew/construction/zhongguoXiongan.png'
import constructionZhongguoDianjian from '../assets/image/cooperation/cooperationNew/construction/zhongguoDianjian.png'
import constructionZhongjiaoYihang from '../assets/image/cooperation/cooperationNew/construction/zhongjiaoYihang.png'
import constructionZhongguoshizhengHuabeishejiyuan from '../assets/image/cooperation/cooperationNew/construction/zhongguoshizhengHuabeishejiyuan.png'
import constructionNantongLiujian from '../assets/image/cooperation/cooperationNew/construction/nantongLiujian.png'
import constructionShunyuan from '../assets/image/cooperation/cooperationNew/construction/shunyuan.png'
import constructionShanghaiXiandaishejiyuan from '../assets/image/cooperation/cooperationNew/construction/shanghaiXiandaishejiyuan.png'
import constructionWuhanShizheng from '../assets/image/cooperation/cooperationNew/construction/wuhanShizheng.png'
import constructionWuhanShizhengyouxiangongsi from '../assets/image/cooperation/cooperationNew/construction/wuhanShizhengyouxiangongsi.png'
import technologyGoulikeji from '../assets/image/cooperation/cooperationNew/technology/goulikeji.png'
import technologyYunlu from '../assets/image/cooperation/cooperationNew/technology/yunlu.png'
import technologyShuyun from '../assets/image/cooperation/cooperationNew/technology/shuyun.png'
import technologyDingdang from '../assets/image/cooperation/cooperationNew/technology/dingdang.png'
import technologyBimatrix from '../assets/image/cooperation/cooperationNew/technology/bimatrix.png'
import technologyDongjiao from '../assets/image/cooperation/cooperationNew/technology/dongjiao.png'
import technologyXiaota from '../assets/image/cooperation/cooperationNew/technology/xiaota.png'
import technologyBoshi from '../assets/image/cooperation/cooperationNew/technology/boshi.png'
export default {
  name: 'Index',
  data () {
    return {
      dialogTableVisible: false,
      newslist: [],
      isMore: false,
      initCooperationList: [
        collegeLanzhouJiaotongUniversity,
        collegeChanganUniversity,
        collegeShenzhenjishuUniversity,
        collegeQinghuaUniversity,
        collegeTongjiUniversity,
        constructionZhejianggaoxin,
        constructionShanghaiShizhengyuan,
        constructionShanghaiZhujianwei,
        constructionLuqiaojituan,
        constructionZhongtie17ju,
        constructionShanghaipuxinGuihuashejiyuan,
        constructionNantongsijian

      ],
      CooperationEndList: [
        constructionShanghaiChengjian,
        constructionZhongguoXiongan,
        constructionZhongguoDianjian,
        constructionZhongjiaoYihang,
        constructionZhongguoshizhengHuabeishejiyuan,
        constructionNantongLiujian,
        constructionShunyuan,
        constructionShanghaiXiandaishejiyuan,
        constructionWuhanShizheng,
        constructionWuhanShizhengyouxiangongsi,
        technologyGoulikeji,
        technologyYunlu,
        technologyShuyun,
        technologyDingdang,
        technologyBimatrix,
        technologyDongjiao,
        technologyXiaota,
        technologyBoshi
      ]
    }
  },
  created () {
    // 注释日期20220617
    // this.$axios.get('http://180.169.91.30:17080/getCityInfo/get')
    // this.$axios.get('about')
    this.getnewslist()
  },
  watch: {
    isMore (val) {
      if (val) {
        this.initCooperationList = [...this.initCooperationList, ...this.CooperationEndList]
      } else {
        this.initCooperationList = this.initCooperationList.splice(0, 12)
      }
    }
  },
  mounted () {
  },
  methods: {
    handleMore () {
      this.isMore = !this.isMore
    },
    gonewsDetails_hj () {
      this.$router.push('newsDetails_new_hj')
    },
    gonewsDetails_nt () {
      this.$router.push('newsDetails_new_nt')
    },
    showvedio () {
      this.dialogTableVisible = true
    },
    gonews () {
      this.$router.push('news')
    },
    to (path) {
      this.$router.push(path)
    },
    tohd () {
      window.open('https://developer.bjblackhole.com', '_blank')
    },
    async getnewslist () {
      const { data } = await this.$axios.get('news/getParams')
      // console.log(data)
      if (data.code === 200) {
        data.data.splice(4)
        this.newslist = data.data
        // console.log(this.newslist)
      } else {
        return this.$message.error('请求错误')
      }
    },
    tonewsdetails (id) {
      this.$router.push({ path: '/newsDetails', query: { id } })
    }
  }
}
</script>

<style scoped lang="less">
*{
  box-sizing: border-box;
}
.alignLeft {
  text-align: left;
}
.page_container{
  .more:hover{
    cursor: pointer;
  }
  .play-root {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    video{
      width: 100%;
      height:100%;
      object-fit:fill;
    }
    .title{
      position: absolute;
      top: 50%;
      left: 50px;
      transform: translateY(-50%);
      width: 35%;
      // background-color: pink;
      color: #fff;
      h1{
        font-size: 50px;
        font-weight: normal;
        line-height: 80px;
        margin-bottom: 20px;
        font-family: "siyuan";
        @media screen and (max-width:768px) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      p{
        font-size: 16px;
      }
      span{
        width: 100px;
        height: 40px;
        background-color: #0aaff1;
        display: block;
        line-height: 40px;
        margin-top: 20px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .nebula{
    width: 100%;
    height: 65vh;
    background: url('../assets/image/nebula/pic1.png') center center;
    color: #fff;
    .title{
      padding-top: 50px;
      overflow: hidden;
      .logo{
        overflow: hidden;
        width: 420px;
        margin: 0 auto;
        height: 70px;
        line-height: 70px;
        img{
          width: 160px;
          height: 100%;
        }
        p{
          font-size: 30px;
        }
      }
      .logo:hover{
        cursor: pointer;
      }
      .info{
        text-align: left;
        margin: 20px 0;
        font-size: 14px;
      }
    }
    .pic{
      overflow: hidden;
      position: relative;
      img{
        // width: 25%;
        object-fit: fill;
        opacity: .5;
      }
      .play{
        i{
          font-size: 70px;
          color: #dadad9;
        }
        i:hover{
          color: #fff;
          cursor: pointer;
        }
      }
    }
    .bottom{
      margin-top: 50px;
      text-align: center;
      font-size: 24px;
      i {
          margin-right:8px
        }
    }
  }
  .swiper{
    .top{
      text-align: center;
      padding: 30px 0;
      h1{
        font-weight: normal;
        font-size: 40px;
      }
      p{
        font-size: 16px;
      }
      p:first-child{
        margin-top: 30px;
      }
    }
    /deep/ .el-carousel{
      width: 80%;
      margin: 50px auto 0;
      .el-carousel__container{
        height: 300px !important;
        @media screen and (max-width:768px) {
            height: 150px !important;
        }
      }
    }
    .more{
      background-color: #0081ff;
      width: 120px;
      margin: 0 auto;
      height: 40px;
      color: #fff;
      border-radius: 20px;
      line-height: 40px;
      text-align: center;
      margin: 30px auto;
    }
    /deep/ .el-carousel__arrow,
    .el-icon-arrow-left,
    .el-icon-arrow-right,
    .el-carousel__item--card.is-in-stage{
      cursor: default;
    }
  }
  .news{
    background-color: #0a2634;
    .top{
      color: #fff;
      font-size: 40px;
      text-align: center;
      padding: 30px 0;
    }
    .newslist{
      padding-bottom: 30px;
      ul{
        overflow: hidden;
        li{
          width: calc(25% - 15px);
          margin-right: 20px;
          float: left;
          height: 400px;
          background-color: #fff;
          img{
            height: 200px;
            object-fit: cover;
            cursor: pointer;
          }
          @media screen and (max-width:768px) {
            width: 90%;
            margin: 20px auto 0 !important;
            float: none;
          }
          .info{
            padding: 10px;
            margin-bottom: 30px;
            .time{
              font-size: 12px;
              color: #888;
              padding: 10px 0 5px;
              letter-spacing: 3px;
              font-weight: 500;
              span{
                position: relative;
                margin-right: 20px;
                letter-spacing: normal;
              }
              span:before {
                content: "";
                width: 15px;
                height: 2px;
                left: 0;
                top: -3px;
                position: absolute;
                background: #888;
              }
            }
            // p:first-child{
            //   font-size: 16px;
            //   color: #414245;
            //   font-weight: 500;
            //   margin-bottom: 10px;
            //   height: 40px;
            //   line-height: 1.3;
            //   text-align-last: left;
            //   text-align: justify;
            //   text-justify: distribute-all-lines;
            //   overflow: hidden;
            //   text-overflow: ellipsis;
            //   -webkit-box-orient: vertical;
            //   cursor: pointer;
            // }
            p{
              cursor: pointer;
            }
            p:last-child{
              font-size: 12px;
              text-indent: 24px;
              margin-top: 5px;
              color: #888;
              cursor: auto;
            }
          }
        }
        li:last-child{
          margin: 0;
        }
      }
      .more{
        text-align: center;
        color: #0081FF;
        font-size: 18px;
        margin-top: 50px;
      }
    }
  }
  .partner{
    margin-bottom: 80px;
    .top{
      margin: 20px 0;
      text-align: center;
      color: #666666;
      p:first-child{
        font-size: 40px;
      }
      p:last-child{
        line-height: 40px;
      }
    }
    .bottom{
      overflow: hidden;
      display: flex;
      // justify-content: space-between;
      // align-content: center;
      flex-wrap: wrap;
      padding: 20px 10px;
      .partner_itemStyle {
        width: calc(25%);
        padding: 1%;
        overflow: hidden;
        position: relative;
        img {
          box-shadow: 0px 7px 20px 0px rgba(205, 215, 236, 0.6500);
        }
        img:hover {
          position: absolute;
          left: 0px;
          right: 0px;
          top: 4px;
          box-shadow: 0px 7px 20px 0px rgba(205, 215, 236, 0.6500);
          // border: 1px #000 solid;
        }
      }
      // ul{
      //   overflow: hidden;
      //   li{
      //     width: calc(25% - 50px);
      //     float: left;
      //     margin: 25px;
      //     overflow: hidden;
      //     box-shadow:10px 10px 5px #888;
      //     a{
      //       img{
      //         width: 100%;
      //         height: 100%;
      //         display: block;
      //       }
      //     }
      //     a,
      //     img{
      //       width: 100%;
      //       height: 100%;
      //       display: block;
      //     }
      //   }
      // }
    }
  }
}
/deep/ .el-dialog{
  // height: 100vh;
  margin: 0;
  background-color: #000;
  .videobox{
    width: 80%;
    margin: 60px auto 0;
    video{
      width: 100%;
      height: 80vh;
    }
  }
  .el-dialog__close{
    font-size: 30px;
  }
}
</style>
